$color-main-background: #9f9f9f;
$color-main-panel: white;
$color-dark: #2f3542;
$color-light: rgb(165, 165, 165);
$color-background-row-trip: rgb(243, 245, 240);
$color-secondary: rgb(44, 114, 44);
$color-success: #1fc830;
$color-warning: #ff4422;
$color-light-background: rgb(212, 219, 208);

$font-menu-footer: 'Zilla Slab', serif;
$font-decorative: 'Zilla Slab', serif;
$font-text: 'Merriweather', serif; 
$font-main-color: rgb(79, 79, 73);

$radius: 5px;
$base-size: 12px;
$transition: all 0.5s;