@import './../../../styles/settings.scss';

.headerRow {
  width: 95%;
  min-height: 50px;
  border-bottom: 1px solid $color-light;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.headerColumn {
  height: 100%;
}

.textMenu {
  font-family: $font-menu-footer;
  font-size: 16px;
  color: black;
  &:hover {
    font-weight: bold;
    transform: none;
  }
}

.textBackground {
  background-color: black;
  padding: 5px 15px;
  border-radius: 20px;
  color: white !important;
  margin-right: 8px;
}

.company {
  text-align: center;
  font-family: $font-decorative;
  font-size: 24px;
  font-weight: bold;
  span {
    font-size: 1.5em;
  }
}

.headerRight {
  max-height: 80%;
  justify-content: end;
  align-items: center;
}

.cart {
  max-height: 46px;
  margin-left: 5px;
  object-fit: cover;
  margin-left: 0;
}

@media (max-width: 992px) {
  .textMenu {
    font-size: 15px
  }
  .company {
    font-size: 19px;
  }
  .textBackground {
    padding: 5px 4px !important;
  }
  .cart {
    max-height: 44px;
  }
}

@media (max-width: 812px) {
  .headerRow {
    min-height: 44px;
    justify-content: end;
  }
  .textMenu {
    font-size: 13px
  }
  .company {
    font-size: 17px;
  }
  .textBackground {
    padding: 3px 5px !important;
    margin-right: 5px;
  }
  .textMenu {
    max-height: 35px;
  }
  .cart {
    max-height: 36px;
  }
  .login {
    margin-right: 4px !important;
  }
}

@media (max-width: 767px) {
  .textMenu {
    font-size: 11px
  }
  .company {
    font-size: 16px;
  }
  .cart {
    max-height: 32px;;
  }
  .rightSide {
    justify-content: end;
  }
}

@media (max-width: 652px) {
  .textBackground {
    text-align: center;
  }
}

@media (max-width: 610px) {
  .textMenu {
    font-size: 12px;
  }
  .company {
    font-size: 18px;
  }
  .textBackground {
    text-align: center;
    padding: 5px 5px;
    margin-right: 0;
  }
  .cart {
    margin-left: 5px;
  }
  .login {
    margin-right: 10px !important;
  }
}