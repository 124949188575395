@import './../../../styles/settings.scss';

.tripRow {
  display: flex;
  height: 180px;
  margin: auto auto;
  align-items: center;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;
  //background-color: $color-background-row-trip;
  border-radius: 8px;
}

.pictureColumn {
  height: 100%;
  width: 35%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.thumbnails {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border: solid 1px $color-dark;
  border-radius: 6px;
}

.descriptionColumn {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  border-top: 2px solid $color-light;
  border-bottom: 2px solid $color-light;
  background-color: $color-light-background;
  border-radius: 20px;
}

.title {
  padding-top: 20px;
  font-family: $font-text;
  font-size: 18px;
  min-height: 82%;
  color: $color-secondary;
  text-align: center;
  font-weight: 600;
}

.country {
  height: 18%;
  text-align: center;
  padding-top: 4px;
  color: $color-dark;
}

.readMoreColumn {
  min-width: 20%;
  margin: auto auto;
  text-align:center;
  padding-left: 5px;
}

.readMore {
  text-align: center;
  margin: auto auto;
  width: 80%;
  border: 1px solid $color-dark;
  border-radius: 5px;
  a {
    color: $color-dark;
  }
  &:hover {
    transform: none;
    background-color: $color-secondary;
    a {
      color: white;
    }
  }
}

@media (max-width: 1200px) {
  .title {
    font-size: 16px;
  }
}

@media (max-width: 890px) {
  .tripRow {
    height: 150px;
  }
  .descriptionColumn {
    width: 45%;
  }
  .title {
    font-size: 13px;
    
  }
  .country {
    font-size: 12px;
  }
  .readMoreColumn {
    max-width: 15%;
    font-size: 10px;
    padding: 0px;
  }
}


@media (max-width: 770px) {
  .tripRow {
    height: 120px;
  }
  .title {
    font-size: 11px;
    padding-top: 5px;
    min-height: 70px;
  }
  .country {
    font-size: 11px;
    padding-top: 5px;
  }
  .readMoreColumn {
    max-width: 12%;
    font-size: 10px;
    a {
      padding: 5px 10px !important;
    }
  }
}

@media (max-width: 625px) {
  .tripRow {
    height: 110px;
  }
  .title {
    font-size: 10px;
    padding-top: 5px;
    min-height: 70px;
  }
  .country {
    font-size: 10px;
  }
  .readMoreColumn {
    max-width: 12%;
    font-size: 9px;
    a {
      padding: 5px 10px !important;
    }
  }
}

@media (max-width: 594px) {
  .tripRow {
    height: auto;
    min-height: 180px;
  }

  .pictureColumn {
    width: 100%;
    margin-bottom: 10px;
  }
  .descriptionColumn {
    width: 100%;
    margin-bottom: 10px;
  }

  .readMoreColumn {
    min-width: 100%;
    .readMore {
      max-width: 120px;
      margin: auto auto;
      .navStyle {
        padding: 0px;
        margin: auto auto;
      }
    }
  }
}



