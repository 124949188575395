@import './../../../styles/settings.scss';

.header {
  font-size: 16px;
  color: black;
}

.button {
  margin: auto auto;
  margin-top: 18px;
  color: white;
  height: 30px;
  width: 120px;
  padding-top: 5px;
  background-color: black;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    color: $color-secondary;
    font-weight: bold;
  }
}

.addedSuccesfully {
  margin: auto auto;
  margin-top: 18px;
  color: white;
  height: 30px;
  width: 220px;
  padding-top: 7px;
  background-color: $color-secondary;
  border-radius: 5px;
  font-size: 11px;
}