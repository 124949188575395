@import './../../../styles/settings.scss';

.agencyInfo {
  height: 100%;
}

.community {
  height: 28%;
  display: flex;
  color: black;
  font-weight: bold;
  font-size: 16px;
  .icons {
    font-size: 30px;
    a {
      color: $color-dark;
    }
  }
  .text {
    font-family: $font-text;
  }
}

.getStarted {
  height: 15%;
  display: flex;
  align-items: center;
  .textBackground {
    display: inline-block;
    height: 34px;
    background-color: $color-secondary;
    padding: 9px 15px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
  }
}

.shortAboutUs {
  display: flex;
  align-items: end;
  padding-bottom: 5px;
  margin-bottom: 5px;
  height: 55%;

  .divBackground {
    height: 80%;
    width: 100%;
    background-color: $color-light-background;
    border-radius: 7px;
    clip-path: polygon(0 0, 90% 0, 100% 40%, 100% 100%, 0 100%);
    display: block;
    align-items: center
  }

  .divPicture {
    padding: 7px 10px;
    width: 21%;
    .image {
      width: 100%;
      object-fit: cover;
      padding: 5px;
      border-radius: 10px;
    }
  }

  .text {
    padding-left: 5px;;
    height: 80%;
    width: 70%;
  }
}

.dot {
  position: relative;
}

.dot::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: $color-secondary;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (max-width: 1024px) {
  .community {
    font-size: 14px;
    .icons {
      font-size: 24px;
    }
  }

  .getStarted {
    .textBackground {
      height: 30px;
      padding: 6px 15px;
      font-size: 12px;
    }
  }
  

  .text {
    font-size: 11px;
    width: 73%;
  }


  .dot::before {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 860px) {
  .community {
    font-size: 12px;
    .icons {
      font-size: 20px;
    }
  }

  .getStarted {
    .textBackground {
      height: 20px;
      padding: 3px 10px;
      font-size: 10px;
    }
  }

  .text {
    font-size: 10px;
  }


  .dot::before {
    width: 6px;
    height: 6px;
  }
}

@media (max-width: 772px) {
  .community {
    font-size: 10px;
    .icons {
      font-size: 16px;
    }
  }

  .shortAboutUs {
    .divPicture {
      padding: 3px 6px;
      .image {
        padding: 3px;
      }
    }
  }

  .text {
    font-size: 8px;
  }
}

@media (max-width: 630px) {
  .community {
    font-size: 9px;
    .icons {
      font-size: 14px;
    }
  }

  .getStarted {
    .textBackground {
      height: 16px;
      padding: 1px 10px;
      font-size: 10px;
      font-weight: normal;
    }
  }

  .shortAboutUs {
    .divPicture {
      padding: 3px 6px;
      .image {
        padding: 3px;
      }
    }
  }
}

@media (max-width: 610px) {
  .agencyInfo {
    margin: auto auto;
    height: 100%;
  }

  .community {
    justify-content: center;
    font-size: 12px;
    flex-wrap: wrap;
    padding-bottom: 5px;

    .icons {
      padding-top: 5px;
      font-size: 18px;
      min-width: 250px;
      margin-left: 0.75rem;
      i {
        margin-right: 0;
      }
    }
  }

  .getStarted {
    padding: 7px 0;
    justify-content: center;
    .textBackground {
      height: 26px;
      padding: 4px 15px;
      font-size: 13px;
      font-weight: normal;
    }
  }
  .shortAboutUs {
    .divPicture {
      padding: 3px 6px;
      .image {
        padding: 3px;
      }
    }
    .divBackground {
      height: 80%;
      width: 100%;
    }
  }


  .text {
    font-size: 11px;
  }
}

@media (max-width: 440px) {
  .text {
    font-size: 9px;
  }
  .getStarted {
    padding: 5px 0;
    justify-content: center;
    .textBackground {
      height: 18px;
      padding: 2px 10px;
      font-size: 10px;
      font-weight: normal;
    }
  }
}

@media (max-width: 370px) {
  .agencyInfo {
    .community {
      .icons {
        min-width: 60px;
      }
    }
    .divBackground {
      clip-path: none;
    }
    .divPicture {
      padding: 3px 3px;
      .image {
        padding: 2px;
      }
    }
  }
}